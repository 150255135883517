import React, { useState } from 'react';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { findOptionsForSelectFilter } from '../../util/search';
import { Footer, ActivityOptionSelector, PageBootstrap } from '../../components';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import './guidespage.scss';
import metaImage from '../../assets/ogp.jpg';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import GuideCard from './GuideCard';

const filterConfig = config.custom.filters;
const activitiesOptions = findOptionsForSelectFilter('activities', filterConfig);



const GuidesPageComponent = props => {
  const [selectedFilter, setSelectedFilter] = useState('all');
  const { guides, intl } = props;
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${metaImage}`;

  return (
    <PageBootstrap
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: metaImage, width: 1200, height: 630 }]}
      twitterImages={[{ url: `${config.canonicalRootURL}${metaImage}`, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'GuidesPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <TopbarContainer bigText={'Guides'} />

      <Container fluid className="staticPageWrapper">
        <ActivityOptionSelector
          options={activitiesOptions}
          onSelect={key => setSelectedFilter(key)}
          selected={selectedFilter}
        />

        <Row className="guidesListWrapper">
          <GuidesList guides={guides} selectedFilter={selectedFilter} />
        </Row>
      </Container>

      <Footer showBanner={false} />
    </PageBootstrap>
  );
};

GuidesPageComponent.defaultProps = {
  guides: [],
};
GuidesPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { guidesIds, guides, fetchGuidesError } = state.GuidesPage;

  return {
    currentUser,
    guides: guides?.data?.data ?? [],
    fetchGuidesError,
  };
};

const GuidesPage = compose(withRouter, connect(mapStateToProps), injectIntl)(GuidesPageComponent);

export default GuidesPage;

const GuidesList = props => {
  const { guides, exclude, selectedFilter, hideSeeMore } = props;

  const finalGuidesArray = guides
    .filter(guide => {
      if (exclude) {
        return !exclude.includes(guide.id.uuid);
      } else {
        return true;
      }
    })
    .filter(g => {
      if (!selectedFilter || selectedFilter === 'all') {
        return true;
      } else {
        return g.hostActivities.includes(selectedFilter);
      }
    })
    .map(g => {
      return (
        <Col lg={4} md={6} sm={6} xs={12}>
          <GuideCard guide={g} />

        </Col>
      );
    });

  return (
    <>
      {finalGuidesArray && finalGuidesArray.length > 0 ? (
        finalGuidesArray
      ) : (
        <p>
          <FormattedMessage id="SectionGuides.noGuidesFound" />
        </p>
      )}
    </>
  );
};
