import config from '../../config';
import React from 'react';

import { NamedLink } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';

import './guidecard.scss';

import noProfileImage from '../LandingPage/noProfileImage.png';
const filterConfig = config.custom.filters;
const activitiesOptions = findOptionsForSelectFilter('activities', filterConfig);

const GuideCard = props => {
  const { guide } = props;
  const hostActivities = guide.hostActivities ? guide.hostActivities : [];

  const parsedActivities = hostActivities.map(a => {
    const found = activitiesOptions.find(x => x.key === a);

    if (found) {
      return found.label;
    } else {
      return false;
    }
  });

  const guideDescription = guide.attributes.profile.bio ?? '';

  return (
    <NamedLink
      name="ProfilePage"
      className="guideSlideLink"
      params={{ locale: config.locale, id: guide.id.uuid }}
    >
      <div className="topSection">
        <img
          loading="lazy"
          className="guidePic"
          src={guide.profileImage.attributes?.variants.default.url ?? noProfileImage}
        />
      </div>
      <div className="bottomSection">
        <p className="kammuiGuideLabel">Kammui Guide</p>
        <p className="guideName">{guide.attributes.profile.displayName}</p>
        <p className="guideDescription">
          {guideDescription?.length > 80 ? guideDescription.slice(0, 77) + '...' : guideDescription}
        </p>
        <div className="activitiesWrapper">
          {parsedActivities.map(y => {
            return (
              <span key={y.props.id} className="singleActivity">
                {y}
              </span>
            );
          })}
        </div>
      </div>
    </NamedLink>
  );
};

export default GuideCard;
